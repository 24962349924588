/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-03",
    versionChannel: "nightly",
    buildDate: "2023-09-03T00:19:43.518Z",
    commitHash: "2996f97578f4b9541029f0318d7b87a60664cf23",
};
